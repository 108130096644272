.carouselB2B___hMHBZ {
  width: 40%;
  border-radius: 11px;
}

.carouselB2B___hMHBZ .slick-dots li.slick-active button:before {
  opacity: 1;
  color: #ed32be;
}
.carouselB2B___hMHBZ .slick-dots li button:before {
  font-size: 16px;
  color: white;
  opacity: 1;
}
.carouselB2B___hMHBZ .slick-dots {
  bottom: 10px;
}
.carouselB2B___hMHBZ .slick-next {
  background: #ed32be;
}
.carouselB2B___hMHBZ .slick-prev {
  background: #ed32be;
}

.contentTecnology___3gluu {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-top: 100px;
}
.titleTecnology___37pUL {
  /*  font-family: Circular Std; */
  font-style: normal;
  font-weight: bold;
  font-size: 54px;
  line-height: 1em !important;
  margin-bottom: 24px;
  color: #000000;
}
.magenta___1ZZPy {
  color: #ed32be;
  font-style: normal;
  font-weight: bold;
  font-size: 54px;
  line-height: 1em !important;
  margin-bottom: 24px;
  text-transform: uppercase;
}

.contentText___3v1Zh {
  margin-right: 29px;
  width: 60%;
}
.textTecnologyEnd___1dtp4 {
  margin-bottom: 24px;
}

@media (min-width: 480px) and (max-width: 768px) {
  .contentTecnology___3gluu {
    flex-direction: column;
    margin-top: 100px;
  }

  .contentTecnology___3gluu .contentText___3v1Zh {
    width: 100%;
  }

  .titleTecnology___37pUL,
  .magenta___1ZZPy {
    font-size: 48px;
  }

  .carouselB2B___hMHBZ {
    width: 100%;
    margin-left: 0;
    margin-bottom: 30px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .contentTecnology___3gluu {
    flex-direction: column;
    margin-top: 50px;
  }

  .contentTecnology___3gluu .contentText___3v1Zh {
    width: 100%;
  }

  .titleTecnology___37pUL,
  .magenta___1ZZPy {
    font-size: 32px;
  }

  .carouselB2B___hMHBZ {
    width: 100%;
    margin-left: 0;
    margin-bottom: 30px;
  }

}
