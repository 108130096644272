.image___cIp1V {
  width: 75%;
}
.container___2-H_S {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 90px 0 56px 0;
}
.logo___I08Wr {
  width: 46%;
  position: absolute;
  bottom: 81%;
  right: 9%;
}
.guide___CdLZu {
  /*  font-family: Circular Std; */
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 118% !important;
  color: #000000;
  font-weight: bold;
  width: 260px;
  position: absolute;
  bottom: 174px;
  left: -100px;
  transform: rotate(-90deg);
}

.number___2Wf0j {
  /* font-family: Circular Std; */
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 118% !important;
  color: #000000;
  width: 260px;
  position: absolute;
  top: 24px;
  left: 27px;
}

@media (min-width: 480px) and (max-width: 768px) {
  .container___2-H_S{
    width: 100%;
    margin-top: 50px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .container___2-H_S{
    width: 100%;
    margin-top: 50px;
  }
}
