.container___1O4JW {
  display: flex;
}

.heroContainerTitle___2768l {
  width: 564px;
  height: 700px;
  margin-top: 150px;
}
.heroTitle___pdXe5 {
  /* font-family: "Circular Std"; */
  font-style: normal;
  font-weight: bold;
  font-size: 54px;
  line-height: 107.5% !important;
  color: #ffffff;
}
.heroSubTitle___3NdH0 {
  /*   font-family: Circular Std; */
  margin: 5% 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: rgba(255, 255, 255, 0.64);
}
.buttonContainer___21Kij {
  margin: 10% 0;
  width: 400px;
  height: 50px;
  display: flex;
  color: red;
  justify-content: space-around;
  align-items: center;
}
.phoneContainer___294ek {
  position: absolute;
  max-width: 686px;
  width: 50%;
  right: 0;
  top: 271px;
}
.buttonGooglePlay___1B686 {
  width: 224px;
}
.buttonApp___1_OcN {
  width: 224px;
}

@media (min-width: 480px) and (max-width: 768px) {

  .container___1O4JW {
    padding: 20px;
    flex-direction: column;
  }

  .heroContainerTitle___2768l {
    width: 100%;
    height: auto;
    margin-top: 100px;
  }

  .heroTitle___pdXe5 {
    font-size: 34px;
  }

  .buttonContainer___21Kij {
    width: 100%;
    margin: 10% 0 0;
  }

  .buttonGooglePlay___1B686,
  .buttonApp___1_OcN {
    width: 50%;
    text-align: center;
  }

  .buttonGooglePlay___1B686 img,
  .buttonApp___1_OcN img {
    width: 100% !important;
    max-width: 200px;
  }

  .phoneContainer___294ek {
    width: 100%;
    position: relative;
    top: 12vh;
    max-width: 400px;
    align-self: center;
    left: calc(50% - 152px);
  }

}

@media (min-width: 320px) and (max-width: 480px) {

  .container___1O4JW {
    padding: 20px;
    flex-direction: column;
  }

  .heroContainerTitle___2768l {
    width: 100%;
    height: auto;
    margin-top: 50px;
  }

  .heroTitle___pdXe5 {
    font-size: 34px;
  }

  .buttonContainer___21Kij {
    width: 100%;
    margin: 10% 0 0;
  }

  .buttonGooglePlay___1B686,
  .buttonApp___1_OcN {
    width: 50%;
    text-align: center;
  }

  .buttonGooglePlay___1B686 img,
  .buttonApp___1_OcN img {
    width: 100% !important;
    max-width: 200px;
  }

  .phoneContainer___294ek {
    width: 100%;
    position: relative;
    top: 7vh;
    right: -48px;
  }

}
