.destination___1mQ8T {
  min-width: 262px;
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 100px;
  background-color: #ffffff;
  cursor: pointer;
}
.destinationSelector___1vXZf {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 55%;
}

.destinationSelector___1vXZf label {
  width: 100%;
  text-align: left;
  position: relative;
}

.destinationSelector___1vXZf label::after {
  content: '';
  width: 24px;
  height: 100%;
  display: block;
  -webkit-mask-image: url(/static/icons/chevron-bottom.svg);
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: right center;
  background-color: black;
  position: absolute;
  right: 0;
  top: 0;
}

.destinationSelector___1vXZf label p:first-child {
  font-weight: 600;
}

.contentLlevameAhi___oyGM4 {
  display: block;
  box-sizing: border-box;
  width: 45%;
}

.llevameAhi___3y4Uh {
  width: 210px;
  border-left: solid 2px gray;
  padding: 2.3% 10px;
  color: #ed32be;
  /* font-family: Circular Std; */
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  cursor: pointer;
}
.selector___1rGTd {
  border: none;
  width: 8em;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 2px;
  color: #ed32be;
}

.modalDestination___3VZqR {
  display: flex;
}

.modalDestinationContent___31KD9 {
  max-height: 310px;
}

.modalDestinationContainer___XV1io{
  flex-wrap: nowrap;
  max-height: 310px;
}

.groupItem___1jYeA {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.modalItem___aQotk {
  background: transparent;
  border: 0;
  text-align: left;
  margin: 0;
  padding: 8px 16px;
  width: 100%;
}
.selected___2PdPW {
  background: #009DC5;
  color: white;
}

@media (min-width: 480px) and (max-width: 768px) {

  .destination___1mQ8T {
    width: 100%;
    flex-direction: row;
    height: auto;
    border-radius: 8px;
  }


  .modalDestination___3VZqR div:first-child {
    width: 100% !important;
    top: 0 !important;
    left: 0 !important;
  }

}

@media (min-width: 320px) and (max-width: 480px) {

  .destination___1mQ8T {
    width: 100%;
    flex-direction: column;
    height: auto;
    border-radius: 8px;
  }

  .destinationSelector___1vXZf {
    flex-direction: row;
    width: 100%;
    align-items: end;
    justify-content: space-around;
    padding: 10px 8px 0;
    box-sizing: border-box;
  }

  .contentLlevameAhi___oyGM4 {
    width: 100%;
    padding: 2%;
  }

  .llevameAhi___3y4Uh {
    border-top: solid 2px gray;
    border-left: 0;
    padding: 0;
    width: 100%;
  }

  .modalDestination___3VZqR div:first-child {
    width: 100% !important;
    top: 0 !important;
    left: 0 !important;
  }
}
