.heroContainer___3LFAQ {
  width: 100%;
  height: 860px;
  font-family: "Circular Std";
  background-image: url("/static/images/LandingNavBar/bakground-discoolver.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@media (min-width: 480px) and (max-width: 768px) {

  .heroContainer___3LFAQ {
    height: auto;
    background-image: url("/static/images/LandingNavBar/bakground-discoolver-tablet.jpg");
  }

}

@media (min-width: 320px) and (max-width: 480px) {

  .heroContainer___3LFAQ {
    height: auto;
    background-image: url("/static/images/LandingNavBar/bakground-discoolver-mobile.jpg");
  }

}
