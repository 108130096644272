.circle___1n3WD {
  width: 24px;
  height: 24px;
  background: #fbd4f1;
  border-radius: 200px;
  color: #ed32be;
  text-align: center;
}
.line___3PXE_ {
  height: 100%;
  width: 3px;
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 24px;
  left: 9px;
}
.conteinerTimeLine___jEpd9 {
  position: relative;
  width: 100%;
}
