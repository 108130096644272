.containerlandingStar___jVG6V {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 171px;
}

.star___3m71t {
  width: 60%;
}

.conteinerText___39ByT {
  position: relative;
  top: -24px;
  left: 35px;
  width: 95%;
}
.subTitleEnd___1AzYR {
  margin-bottom: 32px;
}
.startTitle___3LX7- {
  /*  font-family: Circular Std; */
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 1em !important;
  color: #000000;
  margin-bottom: 16px;
}
.magenta___WkJZ3 {
  /*  font-family: Circular Std; */
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 1em !important;
  color: #ed32be;
  margin-bottom: 16px;
  text-transform: uppercase;
}
.subTitle___1OiUo {
  /*   font-family: Circular Std; */
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.64);
}
.titleSectionsLine___3tUKj {
  /*   font-family: Circular Std; */
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px !important;
  color: #000000;
  margin-bottom: 4px;
}
.paragraphSectionsLine___eYqwI {
  /*  font-family: Circular Std; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px !important;
  color: #000000;
}

.contentMovilImage___3H0ZV {
  width: 40%;
  margin-left: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.movilImage___1I44Z {
  width: 394px;
  max-width: 100%;
}

@media (min-width: 480px) and (max-width: 768px) {
  .containerlandingStar___jVG6V {
    flex-direction: column;
    margin-top: 100px;
  }

  .containerlandingStar___jVG6V .star___3m71t {
    width: 100%;
  }

  .startTitle___3LX7-,
  .magenta___WkJZ3 {
    font-size: 48px;
  }

  .contentMovilImage___3H0ZV {
    width: 100%;
    margin-left: 0;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .containerlandingStar___jVG6V {
    flex-direction: column;
    margin-top: 50px;
  }

  .containerlandingStar___jVG6V .star___3m71t {
    width: 100%;
  }

  .startTitle___3LX7-,
  .magenta___WkJZ3 {
    font-size: 32px;
  }

  .contentMovilImage___3H0ZV {
    width: 100%;
    margin-left: 0;
  }

}
