.containerMagazine___1PtWF {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 168px;
}
.buttonsMagazine___VsYnX {
  padding: 10px;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 485px;
  height: 51px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 100px;
  background-color: #ffffff;
  cursor: pointer;
  margin-top: 10%;
}

.buttonsMagazine___VsYnX div {
  width: 305px;
}
.buttonsMagazine___VsYnX button,
.buttonsMagazine___VsYnX a {
  width: 105px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
}
.labelSelector___TUDMb {
  padding: 7px 28px;
  font-style: normal;
  font-size: 14px;
  line-height: 18px !important;
  color: #000000;
  width: 100%;
  display: block;
  box-sizing: border-box;
  position: relative;
}

.labelSelector___TUDMb p:first-child{
  font-weight: 500;
}

.labelSelector___TUDMb::after {
  content: '';
  width: 24px;
  height: 100%;
  display: block;
  -webkit-mask-image: url(/static/icons/chevron-bottom.svg);
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: right center;
  background-color: black;
  position: absolute;
  right: 4px;
  top: 0;
}

.select___3q1E6 {
  -webkit-appearance: none;
  color: black;
  border-radius: 20px;
  padding-left: 15px;
  border: none;
  border-style: none;
  background-color: -internal-light-dark(rgb(255, 255, 255), rgb(197, 35, 35));
}
.buy___3Sj3H {
  color: #ed32be;
  border: none;
  border-left: 1px solid rgba(0, 0, 0, 0.52);
  text-decoration: none;
  background: transparent;
}
.download___31zAp {
  color: #ed32be;
  border: none;
  border-left: 1px solid rgba(0, 0, 0, 0.52);
  text-decoration: none;
  background: transparent;
}

.option___3iajz {
  /* font-family: Circular Std; */
  margin: 7px 28px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px !important;
  color: rgba(0, 0, 0, 0.52);
}

/* estilos de texto */
.titlemagenta___gQzYz {
  color: #ed32be;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 107px;
  line-height: 1em !important;
}
.title___1lz2U {
  /* font-family: Circular Std; */
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  color: #000000;
  line-height: 1em !important;
  margin-bottom: 24px;
}

.textComponentGuide___55hsZ {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 85px;
}

.groupItem___3SXbP {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.modalItem___3drx7 {
  background: transparent;
  border: 0;
  text-align: left;
  margin: 0;
  padding: 8px 16px;
  width: 100%;
}
.selected___3gcGS {
  background: #009DC5;
  color: white;
}

.modalDestination___1dOLi {
  display: flex;
}

.modalDestinationContent___2C2jS {
  max-height: 310px;
}

.modalDestinationContainer___1cazR{
  flex-wrap: nowrap;
  max-height: 310px;
}
.contentObjectMagazine___2MfH5{
  display: block;
  width: 33%;
}

.contentObjectMagazineMobile___1Y4c5{
  display: none;
  width: 100%;
}

@media (min-width: 480px) and (max-width: 768px) {
  .containerMagazine___1PtWF {
    flex-direction: column-reverse;
    margin-top: 100px;
  }

  .textComponentGuide___55hsZ {
    width: 100%;
    margin-left: 0;
  }

  .titlemagenta___gQzYz,
  .title___1lz2U {
    font-size: 48px;
  }

  .buttonsMagazine___VsYnX {
    flex-direction: column;
    height: auto;
    width: 100%;
    border-radius: 8px;
  }

  .buttonsMagazine___VsYnX .labelSelector___TUDMb {
    margin: 7px 0;
    text-align: center;
  }

  .buttonsMagazine___VsYnX .buy___3Sj3H,
  .buttonsMagazine___VsYnX .download___31zAp{
    border-left: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.52);
    width: 100%;
    height: 36px;
  }

  .modalDestination___1dOLi div:first-child {
    width: 100% !important;
    top: 0 !important;
    left: 0 !important;
  }

  .contentObjectMagazine___2MfH5{
    display: none;
  }

  .contentObjectMagazineMobile___1Y4c5{
    display: block;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .containerMagazine___1PtWF {
    flex-direction: column-reverse;
    margin-top: 50px;
  }

  .textComponentGuide___55hsZ {
    width: 100%;
    margin-left: 0;
  }

  .titlemagenta___gQzYz,
  .title___1lz2U {
    font-size: 32px;
  }

  .buttonsMagazine___VsYnX {
    flex-direction: column;
    height: auto;
    width: 100%;
    border-radius: 8px;
  }

  .buttonsMagazine___VsYnX .labelSelector___TUDMb {
    margin: 7px 0;
    text-align: center;
  }

  .buttonsMagazine___VsYnX .buy___3Sj3H,
  .buttonsMagazine___VsYnX .download___31zAp{
    border-left: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.52);
    width: 100%;
    height: 36px;
  }

  .modalDestination___1dOLi div:first-child {
    width: 100% !important;
    top: 0 !important;
    left: 0 !important;
  }

  .contentObjectMagazine___2MfH5{
    display: none;
  }

  .contentObjectMagazineMobile___1Y4c5{
    display: block;
  }
}
