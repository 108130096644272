.carouselCities___1_rVB {
  width: 600px;
}

@media (min-width: 480px) and (max-width: 768px) {
  .carouselCities___1_rVB {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .carouselCities___1_rVB {
    width: 100%;
  }
}
