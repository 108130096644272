.trustUsItem___3dSgp {
  height: 70px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.trustUsItemImage___2oNAe {
  height: 70px;
  width: auto;
}

.containerTrustUs___1_iWT {
  height: 228px;
  margin: 24px 0;
}

.containerTrustUs___1_iWT .slick-slider .slick-list {
  margin: 2%;
}
.containerTrustUs___1_iWT .slick-slide img {
  display: inherit;
}
.containerTrustUs___1_iWT .slick-prev {
  box-shadow: 1px 4px 4px grey;
  background-color: #ed32be;
}
.containerTrustUs___1_iWT .slick-next {
  box-shadow: 1px 4px 4px grey;
  background-color: #ed32be;
}

.titles___3Vzea {
  /* font-family: Circular Std; */
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 102.5%;
  text-align: center;
  color: #000000;
}
.subtitlesTrusUs___2fjtL {
  /* font-family: Circular Std; */
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 102.5%;
  text-align: center;
  color: rgba(0, 0, 0, 0.64);
  margin-bottom: 27px;
}

.jailButtonBeginning___CJHSm {
  width: 50px;
  height: 50px;
  border-radius: 200%;
  border: solid 2px gray;
}

@media (min-width: 480px) and (max-width: 768px) {
  .containerTrustUs___1_iWT {
    padding: 20px;
  }

}


@media (min-width: 320px) and (max-width: 480px) {
  .containerTrustUs___1_iWT {
    padding: 20px;
  }

}
