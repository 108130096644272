.landingNav___ypUto {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 62px;
}

.ulNavBar___1X0wF {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.linkNav___1jTZv {
  z-index: 10;
  text-decoration: none;
  padding: 26px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

.linkNav___1jTZv.language___Xfv9Y {
  padding: 16px;
  padding-bottom: 0;
  margin: 0 10px;
  margin-bottom: 16px;
  text-transform: uppercase;
}

.linkNav___1jTZv.language___Xfv9Y.mobile___2Lss3 {
  display: none;
}

.logo___KANKd {
  width: 260px;
}

.showMenu___3lmWz{
  box-sizing: border-box;
}

.buttonBurger___3-XZf {
  display: none;
  width: 40px;
  height: 40px;
  -webkit-mask-image: url("/static/icons/hamburger-menu.svg");
  mask-image: url("/static/icons/hamburger-menu.svg");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: 30px;
  mask-size: 30px;
}

.buttonClose___2otXG{
  display: none;
  width: 40px;
  height: 40px;
  -webkit-mask-image: url("/static/icons/close.svg");
  mask-image: url("/static/icons/close.svg");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: 30px;
  mask-size: 30px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
}

.modalItem___2q8gy {
  background: transparent;
  border: 0;
  text-align: left;
  margin: 0;
  padding: 8px 16px;
  width: 100%;
}
.selected___1l-DW {
  background: #009DC5;
  color: white;
}

@media (min-width: 480px) and (max-width: 768px) {

  .landingNav___ypUto {
    flex-direction: row;
    padding-top: 10px;
  }

  .buttonBurger___3-XZf,
  .buttonClose___2otXG {
    display: block;
  }

  .ulNavBar___1X0wF {
    position: fixed;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    background-color: white;
    padding: 32px;
    box-shadow: 0px 16px 32px rgb(0 0 0 / 15%), 0px 3px 8px rgb(0 0 0 / 10%) !important;
    box-sizing: border-box;

  }

  .showMenu___3lmWz .ulNavBar___1X0wF {
    left: 0;
  }

  .linkNav___1jTZv{
    width: 100%;
    color: black;
    font-size: 14px;
    padding: 18px;
  }

  .linkNav___1jTZv.language___Xfv9Y {
    padding: 10px;
    padding-bottom: 0;
    margin: 0 8px;
    margin-bottom: 18px;
  }

  .linkNav___1jTZv.language___Xfv9Y._768___2-f5L {
    display: none;
  }

  .linkNav___1jTZv.language___Xfv9Y.mobile___2Lss3 {
    display: block;
  }
}

@media (min-width: 320px) and (max-width: 480px) {

  .landingNav___ypUto {
    flex-direction: row;
    padding-top: 10px;
  }

  .buttonBurger___3-XZf,
  .buttonClose___2otXG {
    display: block;
  }

  .ulNavBar___1X0wF {
    position: fixed;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    background-color: white;
    padding: 32px 16px;
    box-shadow: 0px 16px 32px rgb(0 0 0 / 15%), 0px 3px 8px rgb(0 0 0 / 10%) !important;
    box-sizing: border-box;
    z-index: 999;
  }

  .showMenu___3lmWz .ulNavBar___1X0wF {
    left: 0;
  }

  .linkNav___1jTZv{
    width: 100%;
    color: black;
    font-size: 12px;
    padding: 4px;
  }

  .linkNav___1jTZv.language___Xfv9Y {
    padding: 4px;
  }

  .linkNav___1jTZv.language___Xfv9Y._768___2-f5L {
    display: none;
  }

  .linkNav___1jTZv.language___Xfv9Y.mobile___2Lss3 {
    display: block;
  }
}
