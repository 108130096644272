.carouselPlaces___1jOid {
  width: 450px;
  height: 348px;
  border-radius: 11px;
  margin-right: 50px;
}

.carouselPlaces___1jOid .slick-dots li.slick-active button:before {
  opacity: 1;
  color: #ed32be;
}
.carouselPlaces___1jOid .slick-dots li button:before {
  font-size: 16px;
  color: white;
  opacity: 1;
}
.carouselPlaces___1jOid .slick-dots {
  bottom: 10px;
}
.carouselPlaces___1jOid .slick-next {
  background: #ed32be;
}
.carouselPlaces___1jOid .slick-prev {
  background: #ed32be;
}

.contentOurSecret___28KPL {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 100px;
}
.titleOurSecrect___2xh1c {
  margin: 0;
  /* font-family: Circular Std; */
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 1em !important;
  color: #000000;
  margin-bottom: 14px;
}

.textOurSecrectEnd___vI5Yk {
  margin-bottom: 24px;
}
.magenta___37dFA {
  color: #ed32be;
  margin: 0;
  /* font-family: Circular Std; */
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 1em !important;
  margin-bottom: 14px;
  text-transform: uppercase;
}

@media (min-width: 480px) and (max-width: 768px) {
  .contentOurSecret___28KPL{
    margin-top: 100px;
    flex-direction: column-reverse;
  }

  .contentOurSecret___28KPL .carouselPlaces___1jOid{
    width: 100%;
  }

  .titleOurSecrect___2xh1c,
  .magenta___37dFA {
    font-size: 48px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .contentOurSecret___28KPL{
    margin-top: 50px;
    flex-direction: column-reverse;
  }

  .contentOurSecret___28KPL .carouselPlaces___1jOid{
    width: 100%;
    height: 239px;
  }

  .titleOurSecrect___2xh1c,
  .magenta___37dFA {
    font-size: 32px;
  }
}
