.centerOrder___3ZLsN {
  margin: auto;
  max-width: 1200px;
  padding: 0 28px;
}

@media (min-width: 480px) and (max-width: 768px) {
  .centerOrder___3ZLsN {
    padding: 0 28px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .centerOrder___3ZLsN {
    padding: 0 28px;
  }
}
