.containerGeneratingOrder___2lecK {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
}
.titleGeneration___3suqG {
  /* font-family: Circular Std; */
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 56px;
  color: #000000;
  line-height: 1em !important;
}
.magenta___1ykLm {
  color: #ed32be;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 56px;
  line-height: 1em !important;
  text-transform: uppercase;
}
.paragraph___t5mSV {
  /* font-family: Circular Std; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  line-height: 102.5% !important;
}

.generatingOrder___1Iioj {
  width: 500px;
  padding-top: 5%;
}
.contentObjectCities___1PcfD {
  display: block;
}
.contentObjectCitiesMobile___1HIR2 {
  display: none;
}
@media (min-width: 480px) and (max-width: 768px) {
  .containerGeneratingOrder___2lecK{
    flex-direction: column;
  }

  .titleGeneration___3suqG,
  .magenta___1ykLm {
    font-size: 48px;
  }

  .paragraph___t5mSV {
    font-size: 14px;
    line-height: 16px;
  }

  .generatingOrder___1Iioj {
    width: 100%;
    margin-bottom: 32px;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .contentObjectCities___1PcfD {
    display: none;
  }
  .contentObjectCitiesMobile___1HIR2 {
    display: block;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .containerGeneratingOrder___2lecK{
    flex-direction: column;
  }

  .titleGeneration___3suqG,
  .magenta___1ykLm {
    font-size: 32px;
  }

  .paragraph___t5mSV {
    font-size: 12px;
    line-height: 12px;
  }

  .generatingOrder___1Iioj {
    width: 100%;
    margin-bottom: 16px;
    padding: 0 8px;
    box-sizing: border-box;
  }
  .contentObjectCities___1PcfD {
    display: none;
  }
  .contentObjectCitiesMobile___1HIR2 {
    display: block;
  }
}
