.contentButtonGo___dZMyO{

}

.buttonGo___13WLK {
  width: 250px;
  height: 51px;
  background: #ed32be;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  border-radius: 28px;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 23px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

@media (min-width: 480px) and (max-width: 768px) {
  .contentButtonGo___dZMyO{
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .contentButtonGo___dZMyO{
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }
}
